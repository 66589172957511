<template>
  <div class="orderStatistics">
    <el-card class="box-card card-one" shadow="never">
      <el-form ref="form" :model="form" inline>
        <el-form-item label="订单编号" prop="orderNumber">
          <el-input v-model="form.orderNumber"></el-input>
        </el-form-item>
        <el-form-item label="车牌号" prop="carNumber">
          <el-input v-model="form.carNumber"></el-input>
        </el-form-item>
        <el-form-item label="开始日期" prop="startDate">
          <el-date-picker type="date" placeholder="选择日期" v-model="form.startDate"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期" prop="endDate">
          <el-date-picker type="date" placeholder="选择日期" v-model="form.endDate"></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button type="primary" class="selectBtn" @click="selectBtn()">查询</el-button>
        <el-button type="info" class="clearBtn" @click="clearBtn()">清空</el-button>
      </div>
    </el-card>
    <el-card class="box-card card-two" shadow="never">
      <el-row>
        <el-button type="primary" class="exportOrders" @click="exportOrders()">导出订单</el-button>
        <el-button type="primary" class="lookMap" @click="lookMap()">查看地图</el-button>
      </el-row>
      <el-row>
        <el-table :data="tableData" stripe border>
          <el-table-column type="selection" align="center"></el-table-column>
          <el-table-column prop="id" label="编号" align="center"></el-table-column>
          <el-table-column prop="orderNumber" label="订单编号" align="center"></el-table-column>
          <el-table-column prop="userName" label="用户名称" align="center"></el-table-column>
          <el-table-column prop="userId" label="用户ID" align="center"></el-table-column>
          <el-table-column prop="iphone" label="联系电话" align="center"></el-table-column>
          <el-table-column prop="idCard" label="身份证号码" align="center"></el-table-column>
          <el-table-column prop="transportType" label="转运类型" align="center"></el-table-column>
          <el-table-column prop="time" label="下单时间" align="center"></el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.length"
        ></el-pagination>
      </el-row>
    </el-card>
  </div>
</template>

<script>

export default {
  name: 'orderStatistics',
  components: {
  },
  data() {
    return {
      form: {
        orderNumber: '',
        carNumber: '',
        startDate: '',
        endDate: ''
      },
      tableData: [{
        id: 1,
        orderNumber: '102020430903310041',
        userName: '测试号01',
        userId: 'wahioiupp',
        iphone: '123****5896',
        idCard: '431034********12356',
        transportType: '普通转运',
        time: '2020-12-23 12:23:12'
      }, {
        id: 2,
        orderNumber: '102020430903310041',
        userName: '测试号01',
        userId: 'wahioiupp',
        iphone: '123****5896',
        idCard: '431034********12356',
        transportType: '普通转运',
        time: '2020-12-23 12:23:12'
      }, {
        id: 3,
        orderNumber: '102020430903310041',
        userName: '测试号01',
        userId: 'wahioiupp',
        iphone: '123****5896',
        idCard: '431034********12356',
        transportType: '普通转运',
        time: '2020-12-23 12:23:12'
      }, {
        id: 4,
        orderNumber: '102020430903310041',
        userName: '测试号01',
        userId: 'wahioiupp',
        iphone: '123****5896',
        idCard: '431034********12356',
        transportType: '普通转运',
        time: '2020-12-23 12:23:12'
      }, {
        id: 5,
        orderNumber: '102020430903310041',
        userName: '测试号01',
        userId: 'wahioiupp',
        iphone: '123****5896',
        idCard: '431034********12356',
        transportType: '普通转运',
        time: '2020-12-23 12:23:12'
      }, {
        id: 6,
        orderNumber: '102020430903310041',
        userName: '测试号01',
        userId: 'wahioiupp',
        iphone: '123****5896',
        idCard: '431034********12356',
        transportType: '普通转运',
        time: '2020-12-23 12:23:12'
      }, {
        id: 7,
        orderNumber: '102020430903310041',
        userName: '测试号01',
        userId: 'wahioiupp',
        iphone: '123****5896',
        idCard: '431034********12356',
        transportType: '普通转运',
        time: '2020-12-23 12:23:12'
      }, {
        id: 8,
        orderNumber: '102020430903310041',
        userName: '测试号01',
        userId: 'wahioiupp',
        iphone: '123****5896',
        idCard: '431034********12356',
        transportType: '普通转运',
        time: '2020-12-23 12:23:12'
      }, {
        id: 9,
        orderNumber: '102020430903310041',
        userName: '测试号01',
        userId: 'wahioiupp',
        iphone: '123****5896',
        idCard: '431034********12356',
        transportType: '普通转运',
        time: '2020-12-23 12:23:12'
      }, {
        id: 10,
        orderNumber: '102020430903310041',
        userName: '测试号01',
        userId: 'wahioiupp',
        iphone: '123****5896',
        idCard: '431034********12356',
        transportType: '普通转运',
        time: '2020-12-23 12:23:12'
      }],
      currentPage4: 0,
      pagesize: 10
    }
  },
  methods: {
    //  重置表单
    clearBtn() {
      this.$refs.form.resetFields()
    },
    // 查询
    selectBtn() {
      console.log(this.form)
    },
    // 查看地图
    lookMap() {
      console.log('查看地图')
    },
    // 导出订单
    exportOrders() {
      console.log('导出订单')
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    }
  }
}
</script>
<style lang="less" scoped>
.card-one {
  margin-top: 20px;
  margin-bottom: 30px;
  /deep/ .el-card__body {
    margin-top: 30px;
    margin-left: 40px;
    padding: 0;
  }
  width: 1470px;
  padding: 0;
  display: flex;

  .el-form {
    :nth-child(-n + 3).el-form-item {
      margin-right: 39px;
    }
    .el-form-item {
      margin-bottom: 28px;
      height: 32px;
      .el-form-item__content {
        height: 32px;
      }
    }
  }
  .btns {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    .selectBtn,
    .clearBtn {
      height: 34px;
    }
    .selectBtn {
      margin-right: 14px;
    }
  }
}
.card-two {
  width: 1470px;
  .el-table {
    margin-top: 13px;
    margin-bottom: 22px;
  }
  .el-pagination {
    // margin-right: auto;
    text-align: right;
  }
}
.el-menu {
  width: 409px;
}
</style>
